<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header-block">
      <van-dropdown-menu
        :close-on-click-outside="false"
        active-color="var(--van-card-price-color)"
      >
        <van-dropdown-item
          v-model="payType"
          :options="options"
          @change="onRefresh"
        />
<!--        <van-dropdown-item :title="PAGELANG.筛选" ref="timeRef">
          <div class="date-block">
            <input
              class="input"
              type="text"
              readonly
              :value="StartTime"
              :placeholder="PAGELANG.起始时间"
              @click="selectDate(1)"
            />
            <label class="iconfont icon-reduce"></label>
            <input
              class="input"
              type="text"
              readonly
              :value="EndTime"
              :placeholder="PAGELANG.结束时间"
              @click="selectDate(2)"
            />
          </div>
          <div style="padding: 16px">
            <van-button
              type="primary"
              block
              round
              plain
              @click="onReset"
              color="var(--van-card-price-color)"
              style="margin-bottom: 10px"
            >
              {{ SYSLANG.重置 }}
            </van-button>
            <van-button
              type="primary"
              block
              round
              @click="onConfirm"
              color="var(--van-card-price-color)"
            >
              {{ SYSLANG.queding }}
            </van-button>
          </div>
        </van-dropdown-item> -->
      </van-dropdown-menu>

 <!--     <van-popup
        v-model:show="showDate"
        safe-area-inset-bottom
        position="bottom"
        @close="onClose"
      >
        <van-date-picker
          v-model="currentDate"
          title="选择日期"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="showDate = false"
          @confirm="confirmDate"
        />
      </van-popup> -->
    </div>

    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="SYSLANG.下拉即可刷新"
      :loosing-text="SYSLANG.释放即可刷新"
      :loading-text="SYSLANG.loading"
    >
      <van-list
        v-model:loading="isloading"
        :finished="finished"
        :finished-text="SYSLANG.finished"
        :loading-text="SYSLANG.loading"
        @load="getCreditLogs"
      >
        <ul class="logs">
          <template v-for="(log, index) in creditlogs" :key="index">
            <li>
              <div class="price">
                <span v-if="log.goodsMoney>0" style="font-size: 14px;">
                	<span style="color: red;">NT${{log.goodsMoney/100}}</span><span style="margin:0 10px;">|</span>餘額{{log.goodsMoneySum/100}}
                </span>
                <span v-if="log.goodsMoney<0" style="font-size: 14px;">
                	<span style="color: red;">NT${{log.goodsMoney/100}}</span><span style="margin:0 10px;">|</span>餘額{{log.goodsMoneySum/100}}
                </span>
                <span v-if="log.Type == 1" class="type">{{
                  PAGELANG.类型支出
                }}</span>
                <span v-if="log.Type == 2" class="type orange">{{
                  PAGELANG.类型退款
                }}</span>
                <span v-if="log.Type == 3" class="type green">{{
                  PAGELANG.类型充值
                }}</span>
                <span v-if="log.Type == 4" class="type">{{
                  PAGELANG.类型平台服务费
                }}</span>
                <span v-if="log.Type == 5" class="type">{{
                  PAGELANG.类型品牌使用费
                }}</span>
				<span v-if="log.type != 17 && log.type != -17" class="type">
				{{log.typeStr}}
				</span>
              </div>
              <div v-if="log.connectCode > 0" class="info">
                {{ ORDERLANG.ordernum }}:{{ log.connectCode }}
              </div>
			  <div v-else class="info">
			     單號:{{log.goodsMoneyCode }}
			  </div>
			  
			  <div v-if="log.checkContent!='赠送报单积分抵扣美体衣'  " class="info">
			    {{log.checkContent}}
			  </div>
              <div class="time">
                {{ PAGELANG.创建时间 }}
                <span>{{ log.createDate }}</span>
              </div>
            </li>
          </template>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>


</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie,formatDateTime} from "../util";
// import MyMenu from "../components/MyMenu.vue";

export default {
  // components: {
  //   MyMenu,
  // },
  computed:{
  	   FiltergoodsMoney() {
  	        return this.agentInfo.goodsMoney/100
  	   },
  	   FilterinMoney(){
  		   return this.agentInfo.inMoney/100
  	   },
  	   FilteroutMoney(){
  		   return this.agentInfo.outMoney/100
  	   }
  		 
  },
  
  data() {
    return {
      options: [],
      showDate: false,
      timeType: 0,
      payType: 0,
      currentDate: [
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ],
      StartTime: "",
      EndTime: "",
      minDate: new Date(new Date().getFullYear() - 10, 0, 1),
      maxDate: new Date(),
      creditlogs: [],
      refreshing: false,
      finished: false,
      isloading: false,
      curpage: 0,
      pagecount: 0,
      agentInfo: {},
      init: true,
      timer: [],
      total: 0,
      pagesize: 0,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.recharge;
    this.ORDERLANG = this.LANG.pages.order;

    this.options = [
      { text:"全部", value: 0 },
      // { text: this.PAGELANG.类型支出, value: 1 },
      // { text: this.PAGELANG.类型充值, value: 3 },
      // { text: this.PAGELANG.类型退款, value: 2 },
      // { text: this.PAGELANG.类型平台服务费, value: 4 },
      // { text: this.PAGELANG.类型品牌使用费, value: 5 },
    ];

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.init = true;
      this.axios
        .get(this.actions.memberMoney)
        .then((res) => {
          closeToast();
         let { code, message, data } = res.data;
		 console.log('code',code)
          if (code == 200) {
            this.agentInfo = data;
            this.init = false;
            this.getCreditLogs();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditLogs.getMemberInfo.error", error);
          //this.getMemberInfo();
        });
    },
    getCreditLogs() {
      if (this.init) {
        return false;
      }

      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;
      this.curpage++;
      this.axios
        .get(this.actions.goodsMoneyList,{params:{
		moneyType: this.payType,
		page: this.curpage,
		starttime: this.StartTime,
		endtime: this.EndTime,
		pageSize:50		
		}})
        .then((res) => {
          closeToast();
          let { code, message} = res.data;
          let { page, list, total,pageTotal, pageSize } = res.data.data;
          if (code==200) {
			list.map((item)=>{
			  	  item.createDate=formatDateTime(item.createDate)
			 
			})
            this.curpage = page * 1;
            this.pagecount=pageTotal;
            this.total = total * 1;
            this.pagesize = pageSize * 1;
            this.creditlogs = this.creditlogs.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.isloading = false;
          this.finished = false;
          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditLogs.getCreditLogs.error", error);
          this.curpage--;
          //this.getCreditLogs();
        });
    },
    // 下拉刷新数据
    onRefresh() {
      this.creditlogs = [];
      this.isloading = false;
      this.finished = false;
      this.curpage = 0;
      this.pagecount = 0;
      this.pagesize = 0;
      this.total = 0;

      this.getCreditLogs();
    },
    onConfirm() {
      this.$refs.timeRef.toggle();
      this.onRefresh();
    },
    onReset() {
      this.currentDate = [
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ];
      this.StartTime = "";
      this.EndTime = "";
      this.minDate = new Date(new Date().getFullYear() - 10, 0, 1);
      this.maxDate = new Date();
    },
    selectDate(type) {
      this.timeType = type;
      if (type == 1) {
        this.minDate = new Date(new Date().getFullYear() - 10, 0, 1);
        if (this.StartTime != "") {
          this.currentDate = this.StartTime.split("-");
        }
        this.maxDate = new Date();
        if (this.EndTime != "") {
          let endTime = this.EndTime.split("-");
          this.maxDate = new Date(endTime[0], endTime[1] - 1, endTime[2]);
        }
      } else if (type == 2) {
        if (this.EndTime != "") {
          this.currentDate = this.EndTime.split("-");
        }
        this.minDate = new Date(new Date().getFullYear() - 10, 0, 1);
        if (this.StartTime != "") {
          let startTime = this.StartTime.split("-");
          this.minDate = new Date(startTime[0], startTime[1] - 1, startTime[2]);
        }
        this.maxDate = new Date();
      }
      this.showDate = true;
    },
    confirmDate(val) {
      let curTime = val.selectedValues.join("-");
      if (this.timeType == 1) {
        this.StartTime = curTime;
        this.minDate = new Date(new Date().getFullYear() - 10, 0, 1);
      } else if (this.timeType == 2) {
        this.EndTime = curTime;
      }
      this.showDate = false;
    },
    onLayar() {
      if (this.timer) {
        this.StartTime = this.timer[0];
        this.EndTime = this.timer[1];
      } else {
        this.StartTime = "";
        this.EndTime = "";
      }
      this.onRefresh();
    },

    changePage(val) {
      this.curpage = val - 1;
      this.creditlogs = [];
      this.getCreditLogs();
    },
  },
};
</script>

<style src="../assets/css/creditlogs.css" scoped></style>
<style scoped>
.el-select-dropdown__item.selected {
  color: var(--van-card-price-color);
}
</style>